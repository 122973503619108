import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import { Box, Divider } from '@material-ui/core'
import classNames from 'classnames'
import get from 'lodash/get'
import set from 'lodash/set'
import theme from '@theme'
import getFormattedDateTime from '@helpers/get-formatted-date-time'
import * as dayjs from 'dayjs'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const useStyles = makeStyles({
  dividerCta: {
    display: 'block',
    alignSelf: 'center',
  },
  headline: {
    marginRight: 'auto',
    color: theme.palette.common.white,
    '& h4': {
      fontWeight: theme.typography.fontWeightLight,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      paddingBottom: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '80%',
      textAlign: 'left',
      paddingBottom: '0px',
    },
  },
  boxCta: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  containerCta: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    '& button': {
      minWidth: '100px',
      padding: '4px 12px',
    },
    '& a': {
      padding: '4px 8px',
    },
  },
  containerCtaLeft: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightBold,
    '& button': {
      minWidth: '100px',
      padding: '4px 12px',
    },
    '& a': {
      padding: '4px 8px',
    },
  },
})

const CtaModulePageContext = (props) => {
  const classes = useStyles(props)
  const { cta } = props.blok || props
  const { modalFormFieldValues } = props
  const meetupIcon = 'https://a.storyblok.com/f/64835/x/22be6d0f76/vector.svg'
  const meetupDate =
    modalFormFieldValues.meetupDate &&
    `${getFormattedDateTime(
      modalFormFieldValues.meetupDate,
      'MMMM DD'
    )}, ${getFormattedDateTime(modalFormFieldValues.meetupDate, 'YYYY')}`
  const hiddenFields = [
    {
      marketoId: 'zimportLastAsset',
      value:
        modalFormFieldValues.meetupTitle +
        ' ' +
        modalFormFieldValues.meetupDate,
    },
  ]
  const meetupDetails = {
    meetupTitle: modalFormFieldValues.meetupTitle,
    meetupDate: meetupDate,
    meetupIcon: meetupIcon,
  }
  const alignmentButton = get(cta, '[0].alignmentButton', false)
  const modal = get(cta, '[0].modal[0]')
  const isRightAlignmentButton = alignmentButton === 'right'
  const isLeftAlignmentButton = alignmentButton === 'left'
  set(modal, 'hiddenFields', hiddenFields)

  return (
    <SbEditable content={props.blok}>
      <ThemeProvider theme={theme}>
        <Box
          className={classNames({
            [classes.boxCta]: isRightAlignmentButton,
          })}
        >
          {isRightAlignmentButton && (
            <Box m={'auto'} className={classes.headline}>
              <Divider className={classes.dividerCta} />
            </Box>
          )}

          <Box
            className={classNames({
              [classes.containerCta]: isRightAlignmentButton,
              [classes.containerCtaLeft]: isLeftAlignmentButton,
            })}
            align={alignmentButton}
          >
            {renderBloks(cta, {
              hiddenFields: hiddenFields,
              meetupDetails: meetupDetails,
            })}
          </Box>
        </Box>
      </ThemeProvider>
    </SbEditable>
  )
}

export default CtaModulePageContext
